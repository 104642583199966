import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import {
  Root,
  Container,
  ReturnLink,
  Body,
  StampImg,
  PrevNext,
  Content,
  Title,
  Desc,
  List,
  Step,
  StepImg,
  StepBody,
  StepDesc,
} from "./Recipe.style";

import StampSrc from "../../images/stamp-beige.svg";

const Recipe = ({recipe, nextRecipeId, previousRecipeId}) => {
  return (
    <Root>
      <Container>
        <ReturnLink>
          <Link to="/recipes">BACK TO ALL RECIPES</Link>
        </ReturnLink>

        <Body>
          <StampImg src={StampSrc} alt="" />
          <PrevNext as={Link} to={"/recipe/?id="+previousRecipeId}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.023"
              height="27.411"
              viewBox="0 0 17.023 27.411"
            >
              <path
                d="M3778.062,4448.672l-14.728-12.913,14.728-12.913"
                transform="translate(-3761.734 -4422.054)"
                fill="none"
                stroke="#ce9b61"
                strokeMiterlimit="10"
                strokeWidth="2.109"
              />
            </svg>
          </PrevNext>

          <Content>
            <Title>{recipe.title}</Title>
            <Desc>
              {recipe.description && <p>{recipe.description}</p> }
            </Desc>

            {recipe.ingredients.length > 0 && <><Title as="h5" className="--subtitle">
              INGREDIENTS
            </Title>
            <Desc>
              <List>
              {recipe.ingredients.map(itm => (<li key={itm.description}>{itm.description}</li>))}
              </List>
            </Desc></>}
            
            {recipe.steps.length > 0 && <><Title as="h5" className="--subtitle">
              DIRECTIONS
            </Title>
            {recipe.steps.map((itm, idx) => (<Step key={itm.description}>
              {itm.thumbnail && <StepImg src={itm.thumbnail} alt="" />}
              <StepBody>
                <Title as="div" className="--subtitle">
                  {`${idx + 1}`.padStart(2, '0')}
                </Title>
                <StepDesc>{itm.description}</StepDesc>
              </StepBody>
            </Step>))} 
           </>}
            
          </Content>

          <PrevNext as={Link} to={"/recipe/?id="+nextRecipeId}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.022"
              height="27.411"
              viewBox="0 0 17.022 27.411"
            >
              <path
                d="M4969.984,4448.672l14.728-12.913-14.728-12.913"
                transform="translate(-4969.289 -4422.054)"
                fill="none"
                stroke="#ce9b61"
                strokeMiterlimit="10"
                strokeWidth="2.109"
              />
            </svg>
          </PrevNext>
        </Body>
      </Container>
    </Root>
  );
};

Recipe.propTypes = {
  nextRecipeId: PropTypes.string.isRequired,
  previousRecipeId: PropTypes.string.isRequired,
  recipe: PropTypes.shape(
    {
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.any.isRequired,
      thumbnail: PropTypes.any.isRequired,
      description: PropTypes.string,
      ingredients: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string
      })),
      steps: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string,
        thumbnail: PropTypes.any
      })),
    }
  )
};

export default Recipe;
