import JamesOwnbyNeatThumbnail from "../../images/recipe/james-ownby-neat-thumbnail.jpg";
import JamesOwnbyNeatBanner from '../../images/recipe/james-ownby-neat-banner.jpg';
import RenegadeOleFashionedThumbnail from '../../images/recipe/renegade-ole-fashioned-thumbnail.jpg'
import RenegadeOleFashionedBanner from '../../images/recipe/renegade-ole-fashioned-banner.jpg'
import TNGoldRushThumbnail from '../../images/recipe/tn-gold-rush-thumbnail.jpg'
import TNGoldRushBanner from '../../images/recipe/tn-gold-rush-banner.jpg'
import JOManhattanThumbnail from '../../images/recipe/jo-manhattan-thumbnail.jpg'
import JOManhattanBanner from '../../images/recipe/jo-manhattan-banner.jpg'
import AppalachianSmashThumbnail from '../../images/recipe/appalachian-smash-thumbnail.jpg'
import AppalachianSmashBanner from '../../images/recipe/appalachian-smash-banner.jpg'
import VolunteerThumbnail from '../../images/recipe/volunteer-thumbnail.jpg'
import VolunteerBanner from '../../images/recipe/volunteer-banner.jpg'

// import JamesOwnbyNeatThumbnail from "../../images/recipe1.jpg";
// import JamesOwnbyNeatBanner from '../../images/hero-recipe-bg-desktop.jpg';
// import Step1Src from "../../images/recipe-step-1.jpg";
// import Step2Src from "../../images/recipe-step-2.jpg";
// import Step3Src from "../../images/recipe-step-3.jpg";
// import Step4Src from "../../images/recipe-step-4.jpg";

export default [
    {
        id: "james-ownby-neat",
        title: "James Ownby Neat",
        shortTitle: "James Ownby Neat",
        image: JamesOwnbyNeatBanner,
        thumbnail: JamesOwnbyNeatThumbnail,
        description: null,
        ingredients: [
            {
                description: '3 OZ James Ownby Reserve Tennessee Straight Bourbon Whiskey'
            }
        ],
        steps: [
            {
                description: "Serve neat or on the rocks",
                //thumbnail: Step1Src
            }
        ]
    },
    {
        id: "renegade-ole-fashioned",
        title: 'Renegade Ole Fashioned',
        shortTitle: "Ole Fashioned",
        image: RenegadeOleFashionedBanner,
        thumbnail: RenegadeOleFashionedThumbnail,
        description: null,
        ingredients: [
            {
                description: '1.5 OZ James Ownby Reserve Tennessee Straight Bourbon Whiskey'
            },
            {
                description: '3 dashes of Bitters'
            },
            {
                description: '0.5 OZ Water'
            },
            {
                description: '1 Sugar Cube'
            },
            {
                description: 'Ole Smoky Moonshine Cherries'
            },
            {
                description: 'Orange Slices'
            }
        ],
        steps: [
            {
                description: "Muddle orange slice and cherries with water, bitters, and sugar",
                //thumbnail: Step1Src
            },
            {
                description: "Add whiskey and pour over ice",
                //thumbnail: Step1Src
            },
            {
                description: "Garnish with fresh orange slices and Ole Smoky Moonshine Cherries",
                //thumbnail: Step1Src
            }
        ]       
    },
    {
        id: "tn-gold-rush",
        title: 'Tennessee Gold Rush',
        shortTitle: "Gold Rush",
        image: TNGoldRushBanner,
        thumbnail: TNGoldRushThumbnail,
        description: null,
        ingredients: [
            {
                description: '2 OZ James Ownby Reserve Tennessee Straight Bourbon Whiskey'
            },
            {
                description: '1 OZ Honey Syrup'
            },
            {
                description: '0.75 OZ Fresh Squeezed Lemon Juice'
            },
        ],
        steps: [
            {
                description: "Mix well in glass over ice",
                //thumbnail: Step1Src
            },
            {
                description: "Garnish with lemon wedge",
                //thumbnail: Step1Src
            },
        ]       
    },
    {
        id: "jo-manhattan",
        title: 'Overmountain Manhattan',
        shortTitle: "Overmountain Manhattan",
        image: JOManhattanBanner,
        thumbnail: JOManhattanThumbnail,
        description: null,
        ingredients: [
            {
                description: '2 OZ James Ownby Reserve Tennessee Straight Bourbon Whiskey'
            },
            {
                description: '0.75 OZ Vermouth'
            },
            {
                description: '0.25 OZ Bitters'
            },
        ],
        steps: [
            {
                description: "Mix well in glass over ice",
                //thumbnail: Step1Src
            },
            {
                description: "Garnish with cherries",
                //thumbnail: Step1Src
            },
        ]       
    },
    {
        id: "appalachian-smash",
        title: 'Appalachian Smash',
        shortTitle: "Appalachian Smash",
        image: AppalachianSmashBanner,
        thumbnail: AppalachianSmashThumbnail,
        description: null,
        ingredients: [
            {
                description: '2 OZ James Ownby Reserve Tennessee Straight Bourbon Whiskey'
            },
            {
                description: '3 Lemon Wedges'
            },
            {
                description: '5-6 Mint Leaves'
            },
        ],
        steps: [
            {
                description: "Muddle 3 lemon wedges & 4 Mint Leaves and poour into a shaker",
                //thumbnail: Step1Src
            },
            {
                description: "Add Bourbon to shaker",
                //thumbnail: Step1Src
            },
            {
                description: "Shake, chill and strain over rocks",
                //thumbnail: Step1Src
            },
            {
                description: "Garnish with mint leaves",
                //thumbnail: Step1Src
            },
        ]       
    },
    {
        id: "volunteer",
        title: 'The Volunteer',
        shortTitle: "The Volunteer",
        image: VolunteerBanner,
        thumbnail: VolunteerThumbnail,
        description: null,
        ingredients: [
            {
                description: '2 OZ James Ownby Reserve Tennessee Straight Bourbon Whiskey'
            },
            {
                description: '0.5 OZ Simple Syrup'
            },
            {
                description: '1 OZ Freshly Squeezed Orange Juice'
            },
            {
                description: 'Soda Water'
            },
        ],
        steps: [
            {
                description: "Mix whiskey, simple syrup and orange juice in glass over ice",
                //thumbnail: Step1Src
            },
            {
                description: "Top with soda water",
                //thumbnail: Step1Src
            },
            {
                description: "Garnish with orange and mint",
                //thumbnail: Step1Src
            },
        ]       
    }
];