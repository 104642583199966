import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  background-color: #cda668; // #eae1d0;

  @media (max-width: 1023px) {
    padding-top: 64px;
    padding-bottom: 50px;
  }

  @media (min-width: 1024px) {
    padding-top: 64px;
    padding-bottom: 50px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1132px;
  padding: 0 24px;
  text-align: center;
`;

export const Title = styled.h4`
  display: flex;
  align-items: center;
  width: 100%;
  color: #141f35;
  font-family: 'GreatWestern';
  font-size: 32px;
  letter-spacing: 0.05em;
  line-height: 1;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    justify-content: center;
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    margin-bottom: 60px;
  }
`;

export const Icon = styled.span`
  &.--desktop {
    @media (max-width: 1023px) {
      display: none;
    }

    @media (min-width: 1024px) {
      display: inline-block;
      font-size: 36px;
    }
  }

  &.--mobile {
    @media (max-width: 1023px) {
      display: block;
      margin-top: 30px;
      font-size: 54px;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 639px) {
    grid-gap: 15px;
  }

  @media (min-width: 640px) {
    grid-gap: 25px;
  }
`;

export const GridItem = styled.div`
  position: relative;
  width: 100%;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }
`;

export const GridLink = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const GridImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
