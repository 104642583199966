import React from "react";

import {
  Root,
  Container,
  Info,
  Title,
  Desc,
  Form,
} from "./Subscribe.style";

import SubscribeForm from "../SubscribeForm/SubscribeForm";

const Subscribe = () => {
  return (
    <Root>
      <Container>
        <Info>
          <Title>JOIN THE JAMES OWNBY RESERVE MAILING LIST</Title>
          <Desc>
            Sign up to get the latest James Ownby Reserve news sent to your inbox
          </Desc>
        </Info>

        <Form>
          <SubscribeForm placeholder="Email" submitText="Sign up" />
        </Form>
      </Container>
    </Root>
  );
};

export default Subscribe;
